.about-content{
    row-gap: 40px;
}

@media screen and (min-width: 768px){
    .about-content{
        text-align: left;
    }
}

@media screen and (min-width: 992px){
    .about-content{
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        column-gap: 60px;
    }
}